import { Suspense } from "react"
import dynamic from "next/dynamic"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import LayoutAdmin from "@/layouts/Admin"
import PermissionGuard from "@/guards/PermissionGuard"
import AuthGuard from "@/guards/AuthGuard"
import LayoutCoreProvider from "@/layouts/CoreProvider"

import type { NextPageWithLayout } from "@/pages/_app"
import type { GetStaticProps } from "next"

const ViewUsers = dynamic(() => import("@/views/Users"), {
  suspense: true,
  ssr: false,
})

const Users: NextPageWithLayout = () => (
  <Suspense fallback="...">
    <ViewUsers />
  </Suspense>
)

Users.getLayout = page => {
  return (
    <LayoutCoreProvider headParams={{ title: ({ t }) => t("Users") }}>
      <AuthGuard>
        <LayoutAdmin>
          <PermissionGuard name="LIST_USER">{page}</PermissionGuard>
        </LayoutAdmin>
      </AuthGuard>
    </LayoutCoreProvider>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "")),
    },
  }
}

export default Users
