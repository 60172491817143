import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles({
  name: "permissionGuard",
})(theme => {
  return {
    permissionDenied: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,
    },
  }
})

export default useStyles
