import React from "react"

import useCmsPermission from "@/hooks/useCmsPermission"

import useStyles from "./PermissionGuard.styles"

import type { PermissionName } from "@/hooks/useCmsPermission"
import { Typography } from "@mui/material"

const PermissionDenied = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.permissionDenied}>
      <Typography fontWeight={600} variant="h1" color="primary.main">
        403
      </Typography>
      <Typography
        marginBottom={2}
        fontWeight={600}
        variant="h4"
        color="primary.light">
        Access denied
      </Typography>
    </div>
  )
}

type PermissionGuardProps = {
  children: any
  name: PermissionName | PermissionName[]
}

const PermissionGuard = (props: PermissionGuardProps) => {
  const { children, name } = props
  const { canAccess, isLoading } = useCmsPermission()

  return isLoading ? null : canAccess(name) ? children : <PermissionDenied />
}

export default PermissionGuard
